import React, { useEffect } from 'react';
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { itemFetch } from '../features/itemSlice';

const Imageroom = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(itemFetch());
    }, [dispatch]);

    const { items: allItems } = useSelector((state) => state.items);
    const filteredItems = allItems.filter((item) => item.category === 'imageroom');
    const galleryItems = allItems.filter((item) => item.category === 'imageroomitems');

    return (
        <div className='grid gap-4'>
            {filteredItems.map((item) => (
                <div className="" key={item._id}>

                    <img
                        src={item.image}
                        alt="gallery"
                        className="w-full h-auto md:h-[90vh] rounded-lg shadow-lg object-cover transition-transform transform group-hover:scale-105"
                    />

                    <div className=" p-4 bg-black bg-opacity-50 rounded-lg">
                        <h2 className="text-white text-xl font-bold">CATEGORY-A</h2>
                        <button
                            onClick={() => navigate('/roomvilla')}
                            className="flex items-center mt-2 px-4 py-2 bg-gray-800 text-white rounded-lg hover:bg-gray-700 transition">
                            <IoMdArrowBack className="text-2xl mr-2" />
                            Back
                        </button>
                    </div>
                </div>
            ))}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4 p-4">
                {galleryItems.map((galleryItem) => (
                    <div className="overflow-hidden rounded-lg shadow-lg" key={galleryItem._id}>
                        <img src={galleryItem.image} alt="gallery item" className="w-full h-auto object-cover" />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Imageroom;




