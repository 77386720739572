import React from 'react';
// import roomimage from '../assets/roomimage.png';
import './roomimage.css';

const Roomimage = () => {
  return (
   
    <div className='roomimage'>
    <h1>The villas</h1>
    <h2>a serene escape with top-tier amenities and stunning views.</h2>
    </div>
  )
}

export default Roomimage;
